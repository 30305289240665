<template>
    <div class="w-full h-full fixed top-0 left-0 rounded-sm flex flex-col items-center justify-center">
        <v-loader />
    </div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch("appModule/resetApplication")
            .finally(() => {
                setTimeout(() => {
                    this.$router.push({ name: "auth.login" });
                }, 1500);
            });
    },
};
</script>
